const timeAgo = (date) => {
    // Convert the input date to a Date object (assuming it's in UTC or a different timezone)
    const pastDate = new Date(date);

    // Get the current time in the local timezone
    const now = new Date();

    // Calculate the difference in seconds
    const seconds = Math.floor((now.getTime() - pastDate.getTime()) / 1000);

    if (seconds < 60) {
        return `${seconds} second${seconds === 1 ? '' : 's'} ago`;
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
        return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
        return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    }

    const days = Math.floor(hours / 24);
    if (days < 30) {
        return `${days} day${days === 1 ? '' : 's'} ago`;
    }

    const months = Math.floor(days / 30);
    if (months < 12) {
        return `${months} month${months === 1 ? '' : 's'} ago`;
    }

    const years = Math.floor(months / 12);
    return `${years} year${years === 1 ? '' : 's'} ago`;
}

const toLocalTime = (milliseconds) => {
    const date = new Date(milliseconds);
    return date.toLocaleString();
}

export {timeAgo, toLocalTime};