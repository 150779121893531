import { useState, useEffect } from 'react';
import {useParams} from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { subscribe, unsubscribe } from "./../Utils/events";
import {
    ChatSquare,
    PersonCircle,
    ThreeDotsVertical
} from "react-bootstrap-icons";
import Spinner from 'react-bootstrap/Spinner';
import {timeAgo, toLocalTime} from "../Utils/timeConversion";
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

const User = () => {
    const reloadPosts = () => getAllPosts();

    const {id} = useParams();
    const {authenticateUser, currentUser, isLoggedIn, logout} = useOutletContext();

    const [thisProfile, setThisProfile] = useState({});
    const [posts, setPosts] = useState([]);

    // error
    const [errorMessage, setErrorMessage] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    // is fetching
    const [loading, setLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    // general disabled state for modal
    const [isCloseButtonOfModalDisabled, setIsCloseButtonOfModalDisabled] = useState(false);
    const [backdropOfModal, setBackdropOfModal] = useState('notstatic');

    // post form states
    const [selectedPost, setSelectedPost] = useState('');
    const [postTitleForEditForm, setPostTitleForEditForm] = useState('');
    const [postContentForEditForm, setPostContentForEditForm] = useState('');

    // modal states
    const [showDeletePostModal, setShowDeletePostModal] = useState(false);
    const [showEditPostModal, setShowEditPostModal] = useState(false);

    // banner states for modal
    const [showBanner, setShowBanner] = useState(false);
    const [bannerType, setBannerType] = useState('');
    const [bannerMessage, setBannerMessage] = useState('');

    // disabled state for post form
    const [isEditPostTitleInputDisabled, setIsEditPostTitleInputDisabled] = useState(false);
    const [isEditPostContentInputDisabled, setIsEditPostContentInputDisabled] = useState(false);
    const [isEditPostButtonDisabled, setIsEditPostButtonDisabled] = useState(true);
    const [isDeletePostButtonDisabled, setIsDeletePostButtonDisabled] = useState(false);

    useEffect(() => {
        authenticateUser();
        getProfile();
    }, []);

    // enable or disable edit post button
    useEffect(() => {
        if (postTitleForEditForm.length >= 1 && postTitleForEditForm.length <= 200 && postContentForEditForm.length >= 1 && postContentForEditForm.length <= 2500) {
            setIsEditPostButtonDisabled(false);
        } else {
            setIsEditPostButtonDisabled(true);
        }
    }, [postTitleForEditForm, postContentForEditForm])

    useEffect(() => {
        subscribe("reloadPosts", reloadPosts);
        return () => {
            unsubscribe("reloadPosts", reloadPosts);
        }
    }, [reloadPosts])

    /**************************************************** GENERAL FUNCTIONS ****************************************************/

    const goToPostPage = (id) => {
        window.location.href = '/socialnetwork/post/' + id;
    }

    const resetForms = () => {
        // field reset
        setPostTitleForEditForm('');
        setPostContentForEditForm('');

        // button reset
        setIsDeletePostButtonDisabled(false)

        // banner reset for modal
        setBannerMessage('')
        setBannerType('');
        setShowBanner(false);

        // banner reset for page
        setBannerMessage('')
        setBannerType('');
        setShowBanner(false);
    }

    const disableModalsAndForms = () => {
        // disable all modals
        setIsCloseButtonOfModalDisabled(true);
        setBackdropOfModal('static')

        // disable all buttons and fields of post modal
        setIsEditPostButtonDisabled(true);
        setIsEditPostTitleInputDisabled(true);
        setIsEditPostContentInputDisabled(true);
        setIsDeletePostButtonDisabled(true);
    }

    const enableModalsAndForms = () => {
        // disable all modals
        setIsCloseButtonOfModalDisabled(false);
        setBackdropOfModal('notstatic')

        // disable all buttons and fields of post modal
        setIsEditPostButtonDisabled(false);
        setIsEditPostTitleInputDisabled(false);
        setIsEditPostContentInputDisabled(false);
        setIsDeletePostButtonDisabled(false);
    }

    /**************************************************** PROFILE FUNCTION ****************************************************/

    const getProfile = async () => {
        setShowErrorMessage(false);
        setLoading(true);
        try {
            const url = 'http://' + process.env.REACT_APP_API_HOST_NAME + ':3000/api/socialnetwork/users/' + id;
            const response = await fetch(url);
            const result = await response.json();
            setLoading(false);
            if (result.status === 'success') {
                setThisProfile(result.data.user);
                await getAllPosts();
            } else {
                setErrorMessage(result.message);
                setShowErrorMessage(true);
            }
        } catch(err) {
            setErrorMessage(err);
            setShowErrorMessage(true);
        }
    }

    /**************************************************** POST FUNCTIONS ****************************************************/

    const getAllPosts = async () => {
        setShowErrorMessage(false);
        setErrorMessage('');
        setLoading(true);
        try {
            const url = 'http://' + process.env.REACT_APP_API_HOST_NAME + ':3000/api/socialnetwork/posts/?createdBy=' + id;
            const response = await fetch(url);
            const result = await response.json();
            setLoading(false);
            if (result.status === 'success') {
                setPosts(result.data.posts)
            } else {
                setErrorMessage(result.message);
                setShowErrorMessage(true);
            }
        } catch(err) {
            setErrorMessage(err);
            setShowErrorMessage(true);
        }
    }

    const startEditPost = (id, title, content) => {
        setSelectedPost(id)
        setPostTitleForEditForm(title);
        setPostContentForEditForm(content);
        setShowEditPostModal(true);
    }

    const startDeletePost = (id) => {
        setSelectedPost(id);
        setShowDeletePostModal(true)
    }

    const deletePost = async () => {
        setShowBanner(false);
        disableModalsAndForms();
        setIsFetching(true);
        try {
            const url = 'http://' + process.env.REACT_APP_API_HOST_NAME + ':3000/api/socialnetwork/posts/' + selectedPost;
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                }
            });
            const result = await response.json();
            setIsFetching(false);
            setBannerMessage(result.message);
            enableModalsAndForms();
            if (result.status === 'success') {
                setShowDeletePostModal(false);
                let newPosts = [...posts];
                newPosts = newPosts.filter(post => post._id !== selectedPost);
                setPosts(newPosts);
            } else {
                setBannerMessage(result.message);
                setBannerType('danger');
                setShowBanner(true);
            }
        } catch (err) {
            enableModalsAndForms();
            setBannerMessage(err)
            setBannerType('danger');
            setShowBanner(true);
        }
    }

    const handleEditPostSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setShowBanner(false);
        disableModalsAndForms();
        setIsFetching(true);
        try {
            const url = 'http://' + process.env.REACT_APP_API_HOST_NAME + ':3000/api/socialnetwork/posts/' + selectedPost;
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
                body: JSON.stringify({ title: postTitleForEditForm, content: postContentForEditForm})
            });
            const result = await response.json();
            setIsFetching(false);
            setBannerMessage(result.message);
            enableModalsAndForms();
            if (result.status === 'success') {
                let newPosts = posts.map(post => {
                    if (post._id === selectedPost) {
                        return result.data.post
                    } else {
                        return post;
                    }
                })
                setPosts(newPosts);
                setShowEditPostModal(false);
                resetForms();
            } else {
                setBannerType('danger');
                setShowBanner(true);
            }
        } catch (err) {
            enableModalsAndForms();
            setBannerMessage(err)
            setBannerType('danger');
            setShowBanner(true);
        }
    }

    /**************************************************** GENERAL ELEMENTS ****************************************************/

    const spinnerElement = <div className="spinnerContainer">
        <Spinner animation="border"/>
    </div>

    const errorMessageElement = <div className="errorContainer">{errorMessage}</div>

    /**************************************************** POST ELEMENTS ****************************************************/

    const postsElement = (
        <ul className="posts">
            {posts.map((post) =>
                <li key={post._id}>
                    <div className="postHeader">
                        <div className="left">
                            <div className="comment"><ChatSquare className="smallIcon"/> {post.commentCount}</div>
                            <div className="createAt">{timeAgo(post.createdAt)}</div>
                        </div>
                        {currentUser.id === post.createdBy && (
                            <Dropdown>
                                <Dropdown.Toggle className="customDropdown"><span
                                    className="dropdownToggleText"><ThreeDotsVertical
                                    className="right"/></span></Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => startEditPost(post._id, post.title, post.content)}>Edit
                                        post</Dropdown.Item>
                                    <Dropdown.Item onClick={() => startDeletePost(post._id)}>Delete
                                        post</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </div>
                    <div className="title" onClick={() => goToPostPage(post._id)}>{post.title}</div>
                    <div className="content" onClick={() => goToPostPage(post._id)}>{post.content}</div>
                    <div className="commentButtonContainer"><Button variant="primary" size="sm" onClick={() => goToPostPage(post._id)}>Comment</Button></div>
                </li>
            )}
        </ul>
    )

    const pageContentElement = (
        <>
            <div className="profile">
                <PersonCircle className="icon"/>
                <div className="name">{thisProfile.name}{(currentUser.id && currentUser.id === thisProfile.id) ? ' (You)' : ''}</div>
            </div>
            <div className="content">
                {posts.length > 0 ? postsElement : <div className="errorContainer">No post found. Be the first to post!</div>}
            </div>
        </>
    )

    /**************************************************** MODALS ****************************************************/

    const editPostModal = (
        <Modal show={showEditPostModal} onHide={() => setShowEditPostModal(false)} className="customModal" backdrop={backdropOfModal} keyboard={false}>
            <Modal.Header>
                <Modal.Title>Edit Post</Modal.Title>
                <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={() => setShowEditPostModal(false)} disabled={isCloseButtonOfModalDisabled}></button>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate onSubmit={handleEditPostSubmit}>
                    <Form.Group className="formGroup">
                        <Form.Control
                            type="text"
                            value={postTitleForEditForm}
                            onChange={e => setPostTitleForEditForm(e.target.value)}
                            min={1}
                            max={200}
                            placeholder="Title"
                            disabled={isEditPostTitleInputDisabled}
                            isInvalid={
                                postTitleForEditForm.length > 200
                            }
                        />
                        <Form.Control.Feedback type="invalid">
                            Title must be less than 200 characters long.
                        </Form.Control.Feedback>
                    </Form.Group >
                    <Form.Group className="formGroup">
                        <Form.Control
                            type="text"
                            value={postContentForEditForm}
                            onChange={e => setPostContentForEditForm(e.target.value)}
                            min={1}
                            max={2500}
                            as="textarea"
                            rows={10}
                            placeholder="Body"
                            disabled={isEditPostContentInputDisabled}
                            isInvalid={
                                postContentForEditForm.length > 2500
                            }
                        />
                        <Form.Control.Feedback type="invalid">
                            Content must be less than 2500 characters long.
                        </Form.Control.Feedback>
                    </Form.Group >
                    <div className="submitButtonContainer">
                        <Button align="center" variant="primary" type="submit" disabled={isEditPostButtonDisabled}>{isFetching ? <div>Save Edit <Spinner animation="border" size="sm" /></div> : 'Save Edit'}</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )

    const deletePostModal = (
        <Modal show={showDeletePostModal} onHide={() => setShowDeletePostModal(false)} className="customModal" backdrop={backdropOfModal} keyboard={false}>
            <Modal.Header>
                <Modal.Title>Delete Post</Modal.Title>
                <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={() => setShowDeletePostModal(false)} disabled={isCloseButtonOfModalDisabled}></button>
            </Modal.Header>
            <Modal.Body>
                {isLoggedIn && showBanner && <Alert className="banner" variant={bannerType} dismissible>{bannerMessage}</Alert>}
                <div>Are you sure you want to delete this post?</div>
                <div className="submitButtonContainer">
                    <Button align="center" variant="primary" onClick={() => deletePost()} disabled={isDeletePostButtonDisabled}>{isFetching ? <div>Delete <Spinner animation="border" size="sm" /></div> : 'Delete'}</Button>
                </div>
            </Modal.Body>
        </Modal>
    )


    return <div className="userPage">
        {loading ? spinnerElement : (showErrorMessage ? errorMessageElement : pageContentElement)}
        {editPostModal}
        {deletePostModal}
    </div>;
};

export default User;