import { useState, useEffect } from 'react';
import {useParams} from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {PersonCircle} from "react-bootstrap-icons";

const Setting = () => {
    const {authenticateUser, currentUser, isLoggedIn, logout} = useOutletContext();

    // is fetching
    const [isFetching, setIsFetching] = useState(false);

    // password form states
    const [currentPasswordInput, setCurrentPasswordInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');
    const [confirmPasswordInput, setConfirmPasswordInput] = useState('');

    // banner states for modal
    const [showBanner, setShowBanner] = useState(false);
    const [bannerType, setBannerType] = useState('');
    const [bannerMessage, setBannerMessage] = useState('');

    // disabled state for password form
    const [isCurrentPasswordInputDisabled, setIsCurrentPasswordInputDisabled] = useState(false);
    const [isPasswordInputDisabled, setIsPasswordInputDisabled] = useState(false);
    const [isConfirmPasswordInputDisabled, setIsConfirmPasswordInputDisabled] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    // touched state
    const [isCurrentPasswordInputTouched, setIsCurrentPasswordInputTouched] = useState(false);
    const [isPasswordInputTouched, setIsPasswordInputTouched] = useState(false);
    const [isConfirmPasswordInputTouched, setIsConfirmPasswordInputTouched] = useState(false);

    useEffect(() => {
        if (currentPasswordInput.length >= 6 && passwordInput.length >= 6 && confirmPasswordInput === passwordInput) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [passwordInput, confirmPasswordInput])

    /**************************************************** GENERAL FUNCTIONS ****************************************************/

    const disableModalsAndForms = () => {
        setIsCurrentPasswordInputDisabled(true);
        setIsPasswordInputDisabled(true);
        setIsConfirmPasswordInputDisabled(true);
        setIsButtonDisabled(true);
    }

    const enableModalsAndForms = () => {
        setIsCurrentPasswordInputDisabled(false);
        setIsPasswordInputDisabled(false);
        setIsConfirmPasswordInputDisabled(false);
        setIsButtonDisabled(false);
    }

    const resetForms = () => {
        setCurrentPasswordInput('');
        setPasswordInput('');
        setConfirmPasswordInput('');

        // set password fields untouched
        setIsCurrentPasswordInputTouched(false);
        setIsPasswordInputTouched(false);
        setIsConfirmPasswordInputTouched(false);

        // disable input
        setIsCurrentPasswordInputDisabled(false);
        setIsPasswordInputDisabled(false);
        setIsConfirmPasswordInputDisabled(false);

        // button
        setIsButtonDisabled(true);
    }

    /**************************************************** PASSWORD FUNCTIONS ****************************************************/

    const handleChangePasswordSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsFetching(true);
        setShowBanner(false);
        disableModalsAndForms();
        try {
            const token = localStorage.getItem('token');
            const url = 'http://' + process.env.REACT_APP_API_HOST_NAME + ':3000/api/socialnetwork/users/updatepassword/';
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt")
                },
                body: JSON.stringify({currentPassword: currentPasswordInput, password: passwordInput, confirmPassword: confirmPasswordInput}),
            });
            const result = await response.json();
            setIsFetching(false);
            setBannerMessage(result.message)
            if (result.status === 'success') {
                setBannerType('success');
                setShowBanner(true);
                enableModalsAndForms();
                resetForms();
                setTimeout(() => {
                    setShowBanner(false);
                }, 2000);
            } else {
                enableModalsAndForms();
                setBannerType('danger');
                setShowBanner(true);
            }
        } catch (err) {
            enableModalsAndForms();
            setBannerMessage(err)
            setShowBanner(true);
            setBannerType('danger');
        }
    }

    const settingElement = (
        <div className="formContainer">
            {showBanner &&
                <div className="banner"><Alert variant={bannerType} dismissible>{bannerMessage}</Alert></div>}
            <div className="profile">
                <PersonCircle className="icon"/>
                <div
                    className="name">{currentUser.name}</div>
            </div>
            <Form noValidate onSubmit={handleChangePasswordSubmit}>
                <Form.Group className="formGroup">
                    <Form.Control
                        type="password"
                        value={currentPasswordInput}
                        onChange={e => setCurrentPasswordInput(e.target.value)}
                        onFocus={e => setIsCurrentPasswordInputTouched(true)}
                        placeholder="Current Password"
                        min={6}
                        isInvalid={
                            isCurrentPasswordInputTouched && currentPasswordInput.length < 6
                        }
                        disabled={isCurrentPasswordInputDisabled}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Password must be at least 6 characters long.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="formGroup">
                    <Form.Control
                        type="password"
                        value={passwordInput}
                        onChange={e => setPasswordInput(e.target.value)}
                        onFocus={e => setIsPasswordInputTouched(true)}
                        placeholder="Password"
                        min={6}
                        isInvalid={
                            isPasswordInputTouched && passwordInput.length < 6
                        }
                        disabled={isPasswordInputDisabled}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Password must be at least 6 characters long.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="formGroup">
                    <Form.Control
                        type="password"
                        value={confirmPasswordInput}
                        onChange={e => setConfirmPasswordInput(e.target.value)}
                        onFocus={e => setIsConfirmPasswordInputTouched(true)}
                        placeholder="Confirm Password"
                        min={6}
                        isInvalid={
                            isConfirmPasswordInputTouched && confirmPasswordInput !== passwordInput
                        }
                        disabled={isConfirmPasswordInputDisabled}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Passwords do not match.
                    </Form.Control.Feedback>
                </Form.Group>
                <div className="submitButtonContainer">
                    <Button align="center" variant="primary" type="submit" disabled={isButtonDisabled}>{isFetching ?
                        <div>Change Password <Spinner animation="border" size="sm"/>
                        </div> : 'Change Password'}</Button>
                </div>
            </Form>
        </div>
    )

    return <div className="settingPage">
        {(isLoggedIn && currentUser) ? settingElement :
            <div className="errorContainer">You must log in to view this page.</div>}
    </div>;
};

export default Setting;