import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./Components/home";
import SocialNetwork from "./Components/socialnetwork";
import Post from "./Components/post";
import User from "./Components/user";
import Newsfeed from "./Components/newsfeed";
import Setting from "./Components/setting";
import ResetPassword from "./Components/resetpassword";

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="socialnetwork" element={<SocialNetwork />} >
                  <Route index element={<Newsfeed />} />
                  <Route path="post">
                      <Route path=":id" element={<Post />} />
                  </Route>
                  <Route path="user">
                      <Route path=":id" element={<User />} />
                  </Route>
                  <Route path="setting" element={<Setting />} />
                  <Route path="resetpassword">
                      <Route path=":token" element={<ResetPassword />} />
                  </Route>
              </Route>
              <Route path="*" element={<Home />} />
          </Routes>
      </BrowserRouter>
  );
}

export default App;