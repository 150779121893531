import { useState, useEffect } from 'react';
import {useParams} from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

const ResetPassword = () => {
    const {token} = useParams();
    const {authenticateUser, currentUser, isLoggedIn, logout} = useOutletContext();

    // is fetching
    const [isFetching, setIsFetching] = useState(false);

    // password form states
    const [passwordInput, setPasswordInput] = useState('');
    const [confirmPasswordInput, setConfirmPasswordInput] = useState('');

    // banner states for modal
    const [showBanner, setShowBanner] = useState(false);
    const [bannerType, setBannerType] = useState('');
    const [bannerMessage, setBannerMessage] = useState('');

    // disabled state for password form
    const [isPasswordInputDisabled, setIsPasswordInputDisabled] = useState(false);
    const [isConfirmPasswordInputDisabled, setIsConfirmPasswordInputDisabled] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    // touched state
    const [isPasswordInputTouched, setIsPasswordInputTouched] = useState(false);
    const [isConfirmPasswordInputTouched, setIsConfirmPasswordInputTouched] = useState(false);

    useEffect(() => {
        logout();
    }, [])

    useEffect(() => {
        if (passwordInput.length >= 6 && confirmPasswordInput === passwordInput) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [passwordInput, confirmPasswordInput])

    /**************************************************** GENERAL FUNCTIONS ****************************************************/

    const disableModalsAndForms = () => {
        setIsPasswordInputDisabled(true);
        setIsConfirmPasswordInputDisabled(true);
        setIsButtonDisabled(true);
    }

    const enableModalsAndForms = () => {
        setIsPasswordInputDisabled(false);
        setIsConfirmPasswordInputDisabled(false);
        setIsButtonDisabled(false);
    }

    /**************************************************** PASSWORD FUNCTIONS ****************************************************/

    const handlePasswordResetSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsFetching(true);
        setShowBanner(false);
        disableModalsAndForms();
        try {
            const url = 'http://' + process.env.REACT_APP_API_HOST_NAME + ':3000/api/socialnetwork/users/resetpassword/' + token;
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({password: passwordInput, confirmPassword: confirmPasswordInput}),
            });
            const result = await response.json();
            setIsFetching(false);
            if (result.status === 'success') {
                setBannerMessage(result.message + ' You are now logged in.')
                setBannerType('success');
                setShowBanner(true);
                localStorage.setItem("jwt", result.token);
                setTimeout(() => {
                    enableModalsAndForms();
                    window.location.href = '/socialnetwork';
                }, 2000);
            } else {
                setBannerMessage(result.message)
                enableModalsAndForms();
                setBannerType('danger');
                setShowBanner(true);
            }
        } catch (err) {
            enableModalsAndForms();
            setBannerMessage(err)
            setShowBanner(true);
            setBannerType('danger');
        }
    }

    return <div className="resetPasswordPage">
        <div className="formContainer">
            {showBanner && <div className="banner"><Alert variant={bannerType}>{bannerMessage}</Alert></div>}
            <h4 className="resetPasswordTitle">Reset Password</h4>
            <Form noValidate onSubmit={handlePasswordResetSubmit}>
                <Form.Group className="formGroup">
                    <Form.Control
                        type="password"
                        value={passwordInput}
                        onChange={e => setPasswordInput(e.target.value)}
                        onFocus={e => setIsPasswordInputTouched(true)}
                        placeholder="Password"
                        min={6}
                        isInvalid={
                            isPasswordInputTouched && passwordInput.length < 6
                        }
                        disabled={isPasswordInputDisabled}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Password must be at least 6 characters long.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="formGroup">
                    <Form.Control
                        type="password"
                        value={confirmPasswordInput}
                        onChange={e => setConfirmPasswordInput(e.target.value)}
                        onFocus={e => setIsConfirmPasswordInputTouched(true)}
                        placeholder="Confirm Password"
                        min={6}
                        isInvalid={
                            isConfirmPasswordInputTouched && confirmPasswordInput !== passwordInput
                        }
                        disabled={isConfirmPasswordInputDisabled}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Passwords do not match.
                    </Form.Control.Feedback>
                </Form.Group>
                <div className="submitButtonContainer">
                    <Button align="center" variant="primary" type="submit" disabled={isButtonDisabled}>{isFetching ? <div>Reset Password <Spinner animation="border" size="sm"/></div> : 'Reset Password'}</Button>
                </div>
            </Form>
        </div>
    </div>;
};

export default ResetPassword;