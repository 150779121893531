import { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import {ChevronLeft, ChevronRight} from 'react-bootstrap-icons';

const Home = () => {
    const [currentImage, setCurrentImage] = useState(0);
    // Preload images
    useEffect(() => {
        const imageUrls = [
            'img/sn0.png',
            'img/sn1.png',
            'img/sn2.png',
            'img/sn3.png',
            'img/sn4.png',
            'img/sn5.png',
            'img/sn6.png',
        ];
        imageUrls.forEach((url) => {
            const img = new Image();
            img.src = url;
        });
    }, []);

    const nextImage = () => {
        setCurrentImage((prev) => (prev < 6 ? prev + 1 : 0));
    };

    const previousImage = () => {
        setCurrentImage((prev) => (prev > 0 ? prev - 1 : 6));
    };

    const goToSocialNetwork = () => {
        window.location.href = "/socialnetwork";
    }

    return (
        <div className="home">
            <div className="sectionTitle">About Me</div>
            <div className="card">
                <div className="cardBody">
                    <img src='img/avatar.jpg' alt="avatar" className="avatar"/>
                    <div className="hello">Hi, I am Au Nguyen. Nice to meet you.</div>
                    <div className="intro">I am a UX Designer and Full Stack Web Developer with 7 years of
                        experience in developing large scale SasS web applications in startup and big tech
                        environments. I am currently based in San Jose, California.
                    </div>
                    <ul className="techStacks">
                        <li>
                            <div className="stackTitle">UX Design</div>
                            <div className="stackList">Figma, Photoshop,</div>
                        </li>
                        <li>
                            <div className="stackTitle">Frontend</div>
                            <div className="stackList">HTML, Javascript, React, CSS, LESS, SASS, Boostrap, Foundation
                            </div>
                        </li>
                        <li>
                            <div className="stackTitle">Backend</div>
                            <div className="stackList">NodeJS, Express, Mongo</div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="sectionTitle">Social Network Project</div>
            <div className="card project">
                <div className="cardBody">
                    <div className="intro">A feature-rich, mobile-friendly social network application focused on simplicity and user
                        interaction. Developed with JavaScript, React, LESS, Bootstrap, Node.js, Express, and MongoDB,
                        it offers seamless authentication, authorization, and a robust system for creating, editing, and
                        deleting posts and comments. Users can manage their content on personalized home pages, explore
                        other users' profiles, and engage with the community through comments and interactions.
                    </div>
                    <div className="customCarousel">
                        <div className="carouselNavItemHolder">
                            <ChevronLeft onClick={() => previousImage()} className="carouselNavItem left"/>
                        </div>
                        <img src={`img/sn${currentImage}.png`} alt="images"/>
                        <div className="carouselNavItemHolder">
                            <ChevronRight onClick={() => nextImage()} className="carouselNavItem right"/>
                        </div>
                    </div>
                    <Button align="center" variant="primary" onClick={() => goToSocialNetwork()}>Visit Project</Button>
                </div>
            </div>
        </div>
    )
};

export default Home;