import { useState, useEffect } from 'react';
import {useParams} from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import {timeAgo, toLocalTime} from "../Utils/timeConversion";
import {
    PersonCircle,
    ThreeDotsVertical
} from "react-bootstrap-icons";
import Spinner from 'react-bootstrap/Spinner';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';

const Post = () => {
    const {id} = useParams();
    const {authenticateUser, currentUser, isLoggedIn, logout} = useOutletContext();

    // is fetching
    const [isFetching, setIsFetching] = useState(false);
    const [isFetchingForPage, setIsFetchingForPage] = useState(false);

    // posts
    const [post, setPost] = useState({});
    const [errorMessageForPost, setErrorMessageForPost] = useState('');
    const [showErrorMessageForPost, setShowErrorMessageForPost] = useState(false);
    const [loadingPost, setLoadingPost] = useState(false);
    const [postTitleForEditForm, setPostTitleForEditForm] = useState('');
    const [postContentForEditForm, setPostContentForEditForm] = useState('');

    // disabled state for post form
    const [isEditPostTitleInputDisabled, setIsEditPostTitleInputDisabled] = useState(false);
    const [isEditPostContentInputDisabled, setIsEditPostContentInputDisabled] = useState(false);
    const [isEditPostButtonDisabled, setIsEditPostButtonDisabled] = useState(true);
    const [isDeletePostButtonDisabled, setIsDeletePostButtonDisabled] = useState(false);

    // comments
    const [sortMode, setSortMode] = useState("latest");
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState([]);
    const [errorMessageForComment, setErrorMessageForComment] = useState('');
    const [showErrorMessageForComment, setShowErrorMessageForComment] = useState(false);
    const [loadingComment, setLoadingComment] = useState(false);
    const [commentForEditForm, setCommentForEditForm] = useState('');
    const [selectedComment, setSelectedComment] = useState('');

    // show modal
    const [showEditCommentModal, setShowEditCommentModal] = useState(false);
    const [showEditPostModal, setShowEditPostModal] = useState(false);
    const [showDeletePostModal, setShowDeletePostModal] = useState(false);
    const [showDeleteCommentModal, setShowDeleteCommentModal] = useState(false);

    // disabled state for comment form
    const [isEditCommentButtonDisabled, setIsEditCommentButtonDisabled] = useState(true);
    const [isCreateCommentButtonDisabled, setIsCreateCommentButtonDisabled] = useState(true);
    const [isEditCommentInputDisabled, setIsEditCommentInputDisabled] = useState(false);
    const [isCreateCommentInputDisabled, setIsCreateCommentInputDisabled] = useState(false);
    const [isDeleteCommentButtonDisabled, setIsDeleteCommentButtonDisabled] = useState(false);

    // general disabled state for modal
    const [isCloseButtonOfModalDisabled, setIsCloseButtonOfModalDisabled] = useState(false);
    const [backdropOfModal, setBackdropOfModal] = useState('notstatic');

    // banner states for modal
    const [showBanner, setShowBanner] = useState(false);
    const [bannerType, setBannerType] = useState('');
    const [bannerMessage, setBannerMessage] = useState('');

    // banner states for page
    const [showBannerForPage, setShowBannerForPage] = useState(false);
    const [bannerTypeForPage, setBannerTypeForPage] = useState('');
    const [bannerMessageForPage, setBannerMessageForPage] = useState('');


    useEffect(() => {
        authenticateUser();
        getPost();
    }, [])

    // enable or disable create comment button
    useEffect(() => {
        if (comment.length >= 1 && comment.length <= 2500) {
            setIsCreateCommentButtonDisabled(false);
        } else {
            setIsCreateCommentButtonDisabled(true);
        }
    }, [comment])

    // enable or disable edit comment button
    useEffect(() => {
        if (commentForEditForm.length >= 1 && commentForEditForm.length <= 2500) {
            setIsEditCommentButtonDisabled(false);
        } else {
            setIsEditCommentButtonDisabled(true);
        }
    }, [commentForEditForm])

    // enable or disable edit post button
    useEffect(() => {
        if (postTitleForEditForm.length >= 1 && postTitleForEditForm.length <= 200 && postContentForEditForm.length >= 1 && postContentForEditForm.length <= 2500) {
            setIsEditPostButtonDisabled(false);
        } else {
            setIsEditPostButtonDisabled(true);
        }
    }, [postTitleForEditForm, postContentForEditForm])

    /**************************************************** GENERAL FUNCTIONS ****************************************************/

    const handleSortModeChange = (mode) => {
        setSortMode(mode);
        getComments(mode);
    }

    const resetForms = () => {
        // field reset
        setCommentForEditForm('');
        setPostTitleForEditForm('');
        setPostContentForEditForm('');
        setComment('');

        // button reset
        setIsEditCommentButtonDisabled(true);
        setIsCreateCommentButtonDisabled(true);
        setIsDeletePostButtonDisabled(false)
        setIsDeleteCommentButtonDisabled(false);

        // banner reset for modal
        setBannerMessage('')
        setBannerType('');
        setShowBanner(false);

        // banner reset for page
        setBannerMessageForPage('')
        setBannerTypeForPage('');
        setShowBannerForPage(false);
    }

    const disableModalsAndForms = () => {
        // disable all modals
        setIsCloseButtonOfModalDisabled(true);
        setBackdropOfModal('static')

        // disable all buttons and fields of comment modal
        setIsEditCommentButtonDisabled(true);
        setIsEditCommentInputDisabled(true);
        setIsCreateCommentButtonDisabled(true);
        setIsCreateCommentInputDisabled(true);
        setIsDeleteCommentButtonDisabled(true);

        // disable all buttons and fields of post modal
        setIsEditPostButtonDisabled(true);
        setIsEditPostTitleInputDisabled(true);
        setIsEditPostContentInputDisabled(true);
        setIsDeletePostButtonDisabled(true);
    }

    const enableModalsAndForms = () => {
        // disable all modals
        setIsCloseButtonOfModalDisabled(false);
        setBackdropOfModal('notstatic')

        // disable all buttons and fields of comment modal
        setIsEditCommentButtonDisabled(false);
        setIsEditCommentInputDisabled(false);
        setIsCreateCommentButtonDisabled(false);
        setIsCreateCommentInputDisabled(false);
        setIsDeleteCommentButtonDisabled(false);

        // disable all buttons and fields of post modal
        setIsEditPostButtonDisabled(false);
        setIsEditPostTitleInputDisabled(false);
        setIsEditPostContentInputDisabled(false);
        setIsDeletePostButtonDisabled(false);
    }

    const goToProfilePage = (id) => {
        window.location.href = '/socialnetwork/user/' + id;
    }

    /**************************************************** COMMENT FUNCTIONS ****************************************************/

    const getComments = async (mode) => {
        setLoadingComment(true);
        setShowErrorMessageForComment(false);
        setErrorMessageForComment('');

        try {
            let sortQuery;
            if (mode === 'latest') {
                sortQuery = '-createdAt';
            } else {
                sortQuery = 'createdAt';
            }
            const url = 'http://' + process.env.REACT_APP_API_HOST_NAME + ':3000/api/socialnetwork/comments/?postId=' + id + '&sort=' + sortQuery;
            const commentRes = await fetch(url);
            const commentResult = await commentRes.json();
            setLoadingComment(false);
            if (commentResult.status === 'success') {
                setComments([...commentResult.data.comments]);
            } else {
                setErrorMessageForComment(commentResult.message);
                setShowErrorMessageForComment(true);
            }

        } catch (err) {
            setLoadingComment(false);
            setErrorMessageForComment(err);
            setShowErrorMessageForComment(true);
        }
    }

    const startEditComment = (commentId, commentContent) => {
        setCommentForEditForm(commentContent);
        setSelectedComment(commentId);
        setShowEditCommentModal(true);
    }

    const handleEditCommentSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setShowBanner(false);
        disableModalsAndForms();
        setIsFetching(true);
        try {
            const url = 'http://' + process.env.REACT_APP_API_HOST_NAME + ':3000/api/socialnetwork/comments/' + selectedComment;
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
                body: JSON.stringify({ content: commentForEditForm, createdBy: currentUser.id }),
            });
            const result = await response.json();
            setIsFetching(false);
            setBannerMessage(result.message);
            if (result.status === 'success') {
                resetForms();
                enableModalsAndForms();
                setShowEditCommentModal(false);
                await getComments(sortMode);
            } else {
                enableModalsAndForms();
                setBannerType('danger');
                setShowBanner(true);
            }
        } catch (err) {
            enableModalsAndForms();
            setBannerMessage(err)
            setBannerType('danger');
            setShowBanner(true);
        }
    }

    const handleDeleteComment = (id) => {
        setShowDeleteCommentModal(true);
        setSelectedComment(id);
    }

    const deleteComment = async (id) => {
        setShowBanner(false);
        disableModalsAndForms();
        setIsFetching(true);

        try {
            const url = 'http://' + process.env.REACT_APP_API_HOST_NAME + ':3000/api/socialnetwork/comments/' + selectedComment;
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                }
            });
            const result = await response.json();
            setIsFetching(false);
            setBannerMessage(result.message);
            enableModalsAndForms();
            if (result.status === 'success') {
                resetForms();
                setShowDeleteCommentModal(false);
                await getComments(sortMode);
            } else {
                setBannerType('danger');
                setShowBanner(true);
            }
        } catch (err) {
            enableModalsAndForms();
            setBannerMessage(err)
            setBannerType('danger');
            setShowBanner(true);
        }
    }

    const handleCreateCommentSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setShowBannerForPage(false);
        disableModalsAndForms();
        setIsFetchingForPage(true);

        try {
            const url = 'http://' + process.env.REACT_APP_API_HOST_NAME + ':3000/api/socialnetwork/comments/';
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
                body: JSON.stringify({ content: comment, postId: id, createdBy: currentUser.id, author: currentUser.name }),
            });
            const result = await response.json();
            setIsFetchingForPage(false);
            setBannerMessageForPage(result.message);
            enableModalsAndForms();
            if (result.status === 'success') {
                resetForms();
                await getComments(sortMode);
            } else {
                setBannerTypeForPage('danger');
                setShowBannerForPage(true);
            }
        } catch (err) {
            enableModalsAndForms();
            setBannerMessageForPage(err)
            setBannerTypeForPage('danger');
            setShowBannerForPage(true);
        }
    }

    /**************************************************** POST FUNCTIONS ****************************************************/

    const getPost = async (mode = 'latest') => {
        setLoadingPost(true);
        setLoadingComment(true);
        setShowErrorMessageForComment(false);
        setErrorMessageForComment('');

        try {
            const urlPost = 'http://' + process.env.REACT_APP_API_HOST_NAME + ':3000/api/socialnetwork/posts/' + id;
            const urlComment = 'http://' + process.env.REACT_APP_API_HOST_NAME + ':3000/api/socialnetwork/comments/?postId=' + id;
            const [postRes, commentRes] = await Promise.all([
                fetch(urlPost),
                fetch(urlComment),
            ]);
            const postResult = await postRes.json();
            const commentResult = await commentRes.json();
            setLoadingPost(false);
            setLoadingComment(false);
            if (postResult.status === 'success' && commentResult.status === 'success') {
                setPost({...postResult.data.post});
                setComments([...commentResult.data.comments]);
            } else {
                if (postResult.status !== 'success' ) {
                    setErrorMessageForPost(postResult.message);
                    setShowErrorMessageForPost(true);
                }
                if (commentResult.status !== 'success') {
                    setErrorMessageForComment(commentResult.message);
                    setShowErrorMessageForComment(true);
                }
            }
        } catch (err) {
            setErrorMessageForPost(err);
            setLoadingPost(false);
            setLoadingComment(false);
            setShowErrorMessageForPost(true);
        }
    }

    const deletePost = async () => {
        setShowBanner(false);
        disableModalsAndForms();
        setIsFetching(true);
        try {
            const url = 'http://' + process.env.REACT_APP_API_HOST_NAME + ':3000/api/socialnetwork/posts/' + post._id;
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                }
            });
            const result = await response.json();
            setIsFetching(false);
            setBannerMessage(result.message);
            enableModalsAndForms();
            if (result.status === 'success') {
                setShowDeletePostModal(false);
                window.location.href = '/socialnetwork';
            } else {
                setBannerMessage(result.message);
                setBannerType('danger');
                setShowBanner(true);
            }
        } catch (err) {
            enableModalsAndForms();
            setBannerMessage(err)
            setBannerType('danger');
            setShowBanner(true);
        }
    }

    const startEditPost = (title, content) => {
        setPostTitleForEditForm(title);
        setPostContentForEditForm(content);
        setShowEditPostModal(true);
    }

    const handleEditPostSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setShowBanner(false);
        disableModalsAndForms();
        setIsFetching(true);
        try {
            const url = 'http://' + process.env.REACT_APP_API_HOST_NAME + ':3000/api/socialnetwork/posts/' + post._id;
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
                body: JSON.stringify({ title: postTitleForEditForm, content: postContentForEditForm})
            });
            const result = await response.json();
            setIsFetching(false);
            setBannerMessage(result.message);
            enableModalsAndForms();
            if (result.status === 'success') {
                setPost(result.data.post);
                setShowEditPostModal(false);
                resetForms();
            } else {
                setBannerType('danger');
                setShowBanner(true);
            }
        } catch (err) {
            enableModalsAndForms();
            setBannerMessage(err)
            setBannerType('danger');
            setShowBanner(true);
        }
    }

    /**************************************************** GENERAL ELEMENTS ****************************************************/

    const spinnerElement = <div className="spinnerContainer">
        <Spinner animation="border"/>
    </div>

    const errorMessageForPostElement = <div className="errorContainer">{errorMessageForPost}</div>
    const errorMessageForCommentElement = <div className="errorContainer">{errorMessageForComment}</div>

    const sortModeElement = (
        <ul className="sortElement">
            <li className={sortMode === "latest" ? 'active' : 'inactive'} onClick={() => handleSortModeChange('latest')}>Latest First</li>
            <li className={sortMode === "oldest" ? 'active' : 'inactive'} onClick={() => handleSortModeChange('oldest')}>Oldest First</li>
        </ul>
    );

    /**************************************************** COMMENTS ELEMENTS ****************************************************/

    const commentsElement = (
        <ul className="comments">
            {comments.map(comment => (
                <li className="comment" key={comment._id}>
                    <div className="header">
                        <div className="left">
                            <div className="icon"><PersonCircle/></div>
                            <div
                                className="author">{comment.author}{(currentUser.id && currentUser.id === comment.createdBy) ? ' (You)' : ''}</div>
                            <div className="createAt">{timeAgo(comment.createdAt)}</div>
                        </div>
                        {currentUser.id === comment.createdBy && (
                            <div className="right">
                                <Dropdown>
                                    <Dropdown.Toggle className="customDropdown"><span
                                        className="dropdownToggleText"><ThreeDotsVertical/></span></Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => startEditComment(comment._id, comment.content)}>Edit
                                            comment</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDeleteComment(comment._id)}>Delete
                                            comment</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        )}
                    </div>
                    <div className="content">{comment.content}</div>
                </li>
            ))}
        </ul>
    )

    const createCommentElement = (
        <div>
            {isLoggedIn && showBannerForPage && <Alert className="banner notModal" variant={bannerTypeForPage} dismissible>{bannerMessageForPage}</Alert>}
            <Form noValidate onSubmit={handleCreateCommentSubmit}>
                <Form.Group className="formGroup">
                    <Form.Control
                        type="text"
                        value={comment}
                        onChange={e => setComment(e.target.value)}
                        placeholder="Comment"
                        min={1}
                        max={2500}
                        disabled={isCreateCommentInputDisabled}
                        isInvalid={
                            comment.length > 2500
                        }
                    />
                    <Form.Control.Feedback type="invalid">
                        Comment must be less than 2500 characters long.
                    </Form.Control.Feedback>
                </Form.Group >
                <div className="submitButtonContainer">
                    <Button size="sm" align="center" variant="primary" type="submit" disabled={isCreateCommentButtonDisabled}>{isFetchingForPage ? <div>Comment <Spinner animation="border" size="sm" /></div> : 'Comment'}</Button>
                </div>
            </Form>
        </div>
    );

    const deleteCommentModal = (
        <Modal show={showDeleteCommentModal} onHide={() => setShowDeleteCommentModal(false)} className="customModal" backdrop={backdropOfModal} keyboard={false}>
            <Modal.Header>
                <Modal.Title>Delete Comment</Modal.Title>
                <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={() => setShowDeleteCommentModal(false)} disabled={isCloseButtonOfModalDisabled}></button>
            </Modal.Header>
            <Modal.Body>
                {isLoggedIn && showBanner && <Alert className="banner" variant={bannerType} dismissible>{bannerMessage}</Alert>}
                <div>Are you sure you want to delete this comment?</div>
                <div className="submitButtonContainer">
                    <Button align="center" variant="primary" onClick={() => deleteComment()} disabled={isDeletePostButtonDisabled}>{isFetching ? <div>Delete <Spinner animation="border" size="sm" /></div> : 'Delete'}</Button>
                </div>
            </Modal.Body>
        </Modal>
    )

    const editCommentModal = (
        <Modal show={showEditCommentModal} onHide={() => setShowEditCommentModal(false)} className="customModal" backdrop={backdropOfModal} keyboard={false}>
            <Modal.Header>
                <Modal.Title>Edit Comment</Modal.Title>
                <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={() => setShowEditCommentModal(false)} disabled={isCloseButtonOfModalDisabled}></button>
            </Modal.Header>
            <Modal.Body>
                {isLoggedIn && showBanner && <Alert className="banner" variant={bannerType} dismissible>{bannerMessage}</Alert>}
                <Form noValidate onSubmit={handleEditCommentSubmit}>
                    <Form.Group className="formGroup">
                        <Form.Control
                            type="text"
                            value={commentForEditForm}
                            onChange={e => setCommentForEditForm(e.target.value)}
                            min={1}
                            max={2500}
                            as="textarea"
                            rows={10}
                            placeholder="Comment"
                            disabled={isEditCommentInputDisabled}
                            isInvalid={
                                commentForEditForm.length > 2500
                            }
                        />
                        <Form.Control.Feedback type="invalid">
                            Comment must be less than 2500 characters long.
                        </Form.Control.Feedback>
                    </Form.Group >
                    <div className="submitButtonContainer">
                        <Button align="center" variant="primary" type="submit" disabled={isEditCommentButtonDisabled}>{isFetching ? <div>Save Edit <Spinner animation="border" size="sm" /></div> : 'Save Edit'}</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )

    /**************************************************** POSTS ELEMENTS ****************************************************/

    const postElement = (
        <div className="post">
            <div className="postHeader">
                <div className="left">
                    <div className="icon" onClick={() => goToProfilePage(post.createdBy)}><PersonCircle/></div>
                    <div className="createBy"  onClick={() => goToProfilePage(post.createdBy)}>{post.author}{currentUser.id === post.createdBy ? ' (You)' : ''}</div>
                    <div className="createAt">{timeAgo(post.createdAt)}</div>
                </div>

                {currentUser.id === post.createdBy && (
                    <Dropdown>
                        <Dropdown.Toggle className="customDropdown"><span className="dropdownToggleText"><ThreeDotsVertical className="right" /></span></Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => startEditPost(post.title, post.content)}>Edit post</Dropdown.Item>
                            <Dropdown.Item onClick={() => setShowDeletePostModal(true)}>Delete post</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </div>
            <div className="title">{post.title}</div>
            <div className="content">{post.content}</div>
            {isLoggedIn && createCommentElement}
            {comments.length > 0 && sortModeElement}
            {loadingComment ? spinnerElement : (showErrorMessageForComment ? errorMessageForCommentElement : (comments.length > 0 ? commentsElement :
                <div className="errorContainer">No comment found. Be the first to comment!</div>))}
        </div>
    )

    const editPostModal = (
        <Modal show={showEditPostModal} onHide={() => setShowEditPostModal(false)} className="customModal" backdrop={backdropOfModal} keyboard={false}>
            <Modal.Header>
                <Modal.Title>Edit Post</Modal.Title>
                <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={() => setShowEditPostModal(false)} disabled={isCloseButtonOfModalDisabled}></button>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate onSubmit={handleEditPostSubmit}>
                    <Form.Group className="formGroup">
                        <Form.Control
                            type="text"
                            value={postTitleForEditForm}
                            onChange={e => setPostTitleForEditForm(e.target.value)}
                            min={1}
                            max={200}
                            placeholder="Title"
                            disabled={isEditPostTitleInputDisabled}
                            isInvalid={
                                postTitleForEditForm.length > 200
                            }
                        />
                        <Form.Control.Feedback type="invalid">
                            Title must be less than 200 characters long.
                        </Form.Control.Feedback>
                    </Form.Group >
                    <Form.Group className="formGroup">
                        <Form.Control
                            type="text"
                            value={postContentForEditForm}
                            onChange={e => setPostContentForEditForm(e.target.value)}
                            min={1}
                            max={2500}
                            placeholder="Body"
                            as="textarea"
                            rows={10}
                            disabled={isEditPostContentInputDisabled}
                            isInvalid={
                                postContentForEditForm.length > 2500
                            }
                        />
                        <Form.Control.Feedback type="invalid">
                            Content must be less than 2500 characters long.
                        </Form.Control.Feedback>
                    </Form.Group >
                    <div className="submitButtonContainer">
                        <Button align="center" variant="primary" type="submit" disabled={isEditPostButtonDisabled}>{isFetching ? <div>Save Edit <Spinner animation="border" size="sm" /></div> : 'Save Edit'}</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )

    const deletePostModal = (
        <Modal show={showDeletePostModal} onHide={() => setShowDeletePostModal(false)} className="customModal" backdrop={backdropOfModal} keyboard={false}>
            <Modal.Header>
                <Modal.Title>Delete Post</Modal.Title>
                <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={() => setShowDeletePostModal(false)} disabled={isCloseButtonOfModalDisabled}></button>
            </Modal.Header>
            <Modal.Body>
                {isLoggedIn && showBanner && <Alert className="banner" variant={bannerType} dismissible>{bannerMessage}</Alert>}
                <div>Are you sure you want to delete this post?</div>
                <div className="submitButtonContainer">
                    <Button align="center" variant="primary" onClick={() => deletePost()} disabled={isDeletePostButtonDisabled}>{isFetching ? <div>Delete <Spinner animation="border" size="sm" /></div> : 'Delete'}</Button>
                </div>
            </Modal.Body>
        </Modal>
    )

    /********************************************************************************************************/

    return <div className="postPage">
        {loadingPost ? spinnerElement : (showErrorMessageForPost ? errorMessageForPostElement : postElement)}
        {editCommentModal}
        {deleteCommentModal}
        {editPostModal}
        {deletePostModal}
    </div>
};

export default Post;
