import { useState, useEffect } from 'react';
import { publish } from "./../Utils/events"
import { jwtDecode } from "jwt-decode";
import NavItem from './navItem'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Outlet} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import {Person, House, BoxArrowRight, BoxArrowInRight, Gear, PersonCircle} from 'react-bootstrap-icons';
import Dropdown from "react-bootstrap/Dropdown";
import Spinner from 'react-bootstrap/Spinner';
const validator = require("email-validator");

const SocialNetwork = () => {

    // current user
    const [currentUser, setCurrentUser] = useState({});

    // is logged in?
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    // is fetching
    const [isFetching, setIsFetching] = useState(false);

    // form states
    const [nameInput, setNameInput] = useState('');
    const [emailInput, setEmailInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');
    const [confirmPasswordInput, setConfirmPasswordInput] = useState('');

    // touched state
    const [isPasswordInputTouched, setIsPasswordInputTouched] = useState(false);
    const [isConfirmPasswordInputTouched, setIsConfirmPasswordInputTouched] = useState(false);

    // disabled states of log in / sign up modal
    const [isNameInputDisabled, setIsNameInputDisabled] = useState(false);
    const [isEmailInputDisabled, setIsEmailInputDisabled] = useState(false);
    const [isPasswordInputDisabled, setIsPasswordInputDisabled] = useState(false);
    const [isConfirmPasswordInputDisabled, setIsConfirmPasswordInputDisabled] = useState(false);

    // disabled states of create post modal

    const [isCreatePostTitleInputDisabled, setIsCreatePostTitleInputDisabled] = useState(false);
    const [isCreatePostContentInputDisabled, setIsCreatePostContentInputDisabled] = useState(false);

    // disabled state

    const [isCloseButtonOfModalDisabled, setIsCloseButtonOfModalDisabled] = useState(false);
    const [isBackToLoginButtonDisabled, setIsBackToLoginButtonDisabled] = useState(false);
    const [backdropOfModal, setBackdropOfModal] = useState('notstatic');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    // banner states
    const [showBanner, setShowBanner] = useState(false);
    const [bannerType, setBannerType] = useState('');
    const [bannerMessage, setBannerMessage] = useState('');

    // log in / sign up modal
    const [showLogInModal, setShowLogInModal] = useState(false);
    const [logInModalMode, setLogInModalMode] = useState('login');

    // create post modal

    const [showCreatePostModal, setShowCreatePostModal] = useState(false);
    const [newPostTitle, setNewPostTitle] = useState('');
    const [newPostContent, setNewPostContent] = useState('');

    // enable or disable submit buttons for log in / sign up form
    useEffect(() => {
        // if it's button for sign up form
        if (logInModalMode === 'signup') {
            if (nameInput.length > 0 && nameInput.length <= 30 && validator.validate(emailInput) && passwordInput.length >= 6 && confirmPasswordInput === passwordInput) {
                setIsButtonDisabled(false);
            } else {
                setIsButtonDisabled(true);
            }
        }
        // if it's button for log in form
        else if (logInModalMode === 'login') {
            if (validator.validate(emailInput) && passwordInput.length >= 1) {
                setIsButtonDisabled(false);
            } else {
                setIsButtonDisabled(true);
            }
        } else {
            if (validator.validate(emailInput)) {
                setIsButtonDisabled(false);
            } else {
                setIsButtonDisabled(true);
            }
        }
    }, [nameInput, emailInput, passwordInput, confirmPasswordInput, logInModalMode])

    // enable or disable post buttons for create post form
    useEffect(() => {
        if (newPostTitle.length > 0 && newPostTitle.length <= 200 && newPostContent.length > 0 && newPostContent.length <= 2500) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [newPostTitle, newPostContent])

    // check if token expired and log out accordingly
    useEffect(() => {
        authenticateUser();
    }, [])

    /**************************** NAVIGATION FUNCTIONS **************************/

    const goToProfilePage = () => {
        window.location.href = '/socialnetwork/user/' + currentUser.id;
    }

    const goToHomePage = () => {
        window.location.href = '/socialnetwork';
    }

    // protected page, need to be logged in
    const goToSettingPage = () => {
        window.location.href = '/socialnetwork/setting';
    }

    /**************************** AUTHENTICATION FUNCTIONS **************************/

        // if token expired, log out and remove token from local storage
    const authenticateUser = () => {
            // no token, log out
            if (!localStorage.getItem('jwt')) {
                logout();
            } else {
                const decoded = jwtDecode(localStorage.getItem('jwt'));
                // Expired
                if (decoded.exp < Date.now() / 1000) {
                    localStorage.removeItem('jwt');
                    logout();
                } else {
                    setIsLoggedIn(true);
                    setCurrentUser({id: decoded.id, name: decoded.name});
                }
            }
        }

    /**************************** GENERAL FUNCTIONS **************************/

    const resetForms = () => {
        // reset log in fields
        setNameInput('');
        setEmailInput('');
        setPasswordInput('');
        setConfirmPasswordInput('');

        // set password fields untouched
        setIsPasswordInputTouched(false);
        setIsConfirmPasswordInputTouched(false);

        // disable input
        setIsNameInputDisabled(false);
        setIsEmailInputDisabled(false);
        setIsPasswordInputDisabled(false);
        setIsConfirmPasswordInputDisabled(false);

        // button
        setIsButtonDisabled(true);
        setIsBackToLoginButtonDisabled(false);

        // hide and reset banner
        setShowBanner(false);
        setBannerType('');
        setBannerMessage('');

        // reset create post form
        setNewPostTitle('');
        setNewPostContent('');
    }

    const handleCloseLoginModal = () => {
        setShowLogInModal(false);
        resetForms();
    };

    const handleCloseCreatePostModal = () => {
        setShowCreatePostModal(false);
        resetForms();
    };

    const switchToForgotPasswordForm = () => {
        resetForms()
        setLogInModalMode('forgot')
    }

    const switchToLoginForm = () => {
        resetForms()
        setLogInModalMode('login')
    }

    const switchToSignupForm = () => {
        resetForms()
        setLogInModalMode('signup')
    }

    const getLoginModalTitle = () => {
        let result;
        if (logInModalMode === 'login') {
            result = 'Log In'
        } else if (logInModalMode === 'signup') {
            result = 'Sign Up'
        } else {
            result = 'Reset your password'
        }
        return result;
    }

    const disableModalsAndForms = () => {
        // disable all modals
        setIsCloseButtonOfModalDisabled(true);
        setBackdropOfModal('static')

        // disable all buttons and fields of log in modal
        setIsButtonDisabled(true);
        setIsNameInputDisabled(true);
        setIsEmailInputDisabled(true);
        setIsPasswordInputDisabled(true);
        setIsConfirmPasswordInputDisabled(true);
        setIsBackToLoginButtonDisabled(true);

        // disable all buttons and fields of create post modal
        setIsCreatePostTitleInputDisabled(true);
        setIsCreatePostContentInputDisabled(true);
    }

    const enableModalsAndForms = () => {
        // enable all modals
        setIsCloseButtonOfModalDisabled(false);
        setBackdropOfModal('')

        // enable all buttons and fields of log in modal
        setIsButtonDisabled(false);
        setIsNameInputDisabled(false);
        setIsEmailInputDisabled(false);
        setIsPasswordInputDisabled(false);
        setIsConfirmPasswordInputDisabled(false);
        setIsBackToLoginButtonDisabled(false);

        // enable all buttons and fields of create post modal
        setIsCreatePostTitleInputDisabled(false);
        setIsCreatePostContentInputDisabled(false);
    }

    const openLoginModal = () => {
        setLogInModalMode('login');
        resetForms();
        setShowLogInModal(true);
    }

    const openCreatePostModal = () => {
        resetForms();
        setShowCreatePostModal(true)
    }

    /**************************** LOG IN FUNCTIONS **************************/

    const handleLoginSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsFetching(true);
        setShowBanner(false);
        disableModalsAndForms();
        try {
            const url = 'http://' + process.env.REACT_APP_API_HOST_NAME + ':3000/api/socialnetwork/users/login/';
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({email: emailInput, password: passwordInput})
            });
            const result = await response.json();
            setIsFetching(false);
            setBannerMessage(result.message)
            if (result.status === 'success') {
                setBannerType('success');
                setShowBanner(true);
                setIsLoggedIn(true);
                localStorage.setItem("jwt", result.token);
                setCurrentUser({id: result.user.id, name: result.user.name});
                setTimeout(() => {
                    setShowLogInModal(false);
                    resetForms();
                    enableModalsAndForms();
                }, 2000);
            } else {
                enableModalsAndForms();
                setBannerType('danger');
                setShowBanner(true);
                setIsLoggedIn(false);
            }
        } catch (err) {
            enableModalsAndForms();
            setIsLoggedIn(false);
            setBannerMessage(err)
            setShowBanner(true);
            setBannerType('danger');
        }
    }

    const logout = () => {
        setCurrentUser({});
        setIsLoggedIn(false);
        localStorage.removeItem("jwt");
    }

    /**************************** SIGN UP FUNCTIONS **************************/

    const handleSignupSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsFetching(true);
        setShowBanner(false);
        disableModalsAndForms();
        try {
            const url = 'http://' + process.env.REACT_APP_API_HOST_NAME + ':3000/api/socialnetwork/users/signup/';
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: nameInput,
                    email: emailInput.toLowerCase(),
                    password: passwordInput,
                    confirmPassword: confirmPasswordInput
                })
            });
            const result = await response.json();
            setIsFetching(false);
            setBannerMessage(result.message)
            if (result.status === 'success') {
                setBannerType('success');
                setShowBanner(true);
                setIsLoggedIn(true);
                localStorage.setItem("jwt", result.token);
                setCurrentUser({id: result.data.user.id, name: result.data.user.name});
                setTimeout(() => {
                    setShowLogInModal(false);
                    resetForms();
                    enableModalsAndForms();
                }, 2000);
            } else {
                enableModalsAndForms();
                setBannerType('danger');
                setShowBanner(true);
                setIsLoggedIn(false);
            }
        } catch (err) {
            enableModalsAndForms();
            setBannerMessage(err)
            setBannerType('danger');
            setShowBanner(true);
            setIsLoggedIn(false);
        }
    }

    /**************************** FORGOT PASSWORD FUNCTIONS **************************/

    const handleForgotPasswordSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsFetching(true);
        setShowBanner(false);
        disableModalsAndForms();
        try {
            const url = 'http://' + process.env.REACT_APP_API_HOST_NAME + ':3000/api/socialnetwork/users/forgotpassword/';
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({email: emailInput})
            });
            const result = await response.json();
            setIsFetching(false);
            setBannerMessage(result.message)
            if (result.status === 'success') {
                setBannerType('success');
                setShowBanner(true);
                setTimeout(() => {
                    setShowLogInModal(false);
                    resetForms();
                    enableModalsAndForms();
                }, 2000);
            } else {
                enableModalsAndForms();
                setBannerType('danger');
                setShowBanner(true);
            }
        } catch (err) {
            enableModalsAndForms();
            setBannerMessage(err)
            setShowBanner(true);
            setBannerType('danger');
        }
    }

    /**************************** CREATE POST FUNCTIONS **************************/

    const handleCreatePostSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsFetching(true);
        setShowBanner(false);
        disableModalsAndForms();

        try {
            const url = 'http://' + process.env.REACT_APP_API_HOST_NAME + ':3000/api/socialnetwork/posts/';
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
                body: JSON.stringify({
                    title: newPostTitle,
                    content: newPostContent,
                    createdBy: currentUser.id,
                    author: currentUser.name
                })
            });
            const result = await response.json();
            setIsFetching(false);
            setBannerMessage(result.message)
            enableModalsAndForms();
            publish('reloadPosts');
            if (result.status === 'success') {
                setShowCreatePostModal(false);
                resetForms();

            } else {
                setBannerType('danger');
                setShowBanner(true);
            }
        } catch (err) {
            enableModalsAndForms();
            setBannerMessage(err)
            setBannerType('danger');
            setShowBanner(true);
        }
    }

    /**************************************************** ELEMENTS ****************************************************/

    const loginForm = (
        <Form noValidate onSubmit={handleLoginSubmit}>
            <Form.Group className="formGroup">
                <Form.Control
                    type="email"
                    value={emailInput}
                    onChange={e => setEmailInput(e.target.value)}
                    placeholder="Email"
                    min={1}
                    isInvalid={
                        emailInput.length > 0 && !validator.validate(emailInput)
                    }
                    disabled={isEmailInputDisabled}
                    required
                />
                <Form.Control.Feedback type="invalid">
                    Please provide a valid email.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="formGroup">
                <Form.Control
                    type="password"
                    value={passwordInput}
                    onChange={e => setPasswordInput(e.target.value)}
                    onFocus={e => setIsPasswordInputTouched(true)}
                    placeholder="Password"
                    min={1}
                    isInvalid={
                        isPasswordInputTouched && passwordInput.length < 1
                    }
                    disabled={isPasswordInputDisabled}
                    required
                />
                <Form.Control.Feedback type="invalid">
                    Password is required.
                </Form.Control.Feedback>
            </Form.Group>
            <div className="submitButtonContainer">
                <Button align="center" variant="primary" type="submit" disabled={isButtonDisabled}>{isFetching ?
                    <div>Log In <Spinner animation="border" size="sm"/></div> : 'Log In'}</Button>
            </div>
        </Form>
    );

    const signupForm = (
        <Form noValidate onSubmit={handleSignupSubmit}>
            <Form.Group>
                <Form.Control
                    type="text"
                    value={nameInput}
                    onChange={e => setNameInput(e.target.value)}
                    placeholder="Name"
                    min={1}
                    max={30}
                    isInvalid={
                        nameInput.length > 0 && (nameInput.length === 0 || nameInput.length > 30)
                    }
                    disabled={isNameInputDisabled}
                    required
                />
                <Form.Control.Feedback type="invalid">
                    Name should be less than 30 characters long.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="formGroup">
                <Form.Control
                    type="email"
                    value={emailInput}
                    onChange={e => setEmailInput(e.target.value)}
                    placeholder="Email"
                    min={1}
                    isInvalid={
                        emailInput.length > 0 && !validator.validate(emailInput)
                    }
                    disabled={isEmailInputDisabled}
                    required
                />
                <Form.Control.Feedback type="invalid">
                    Please provide a valid email.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="formGroup">
                <Form.Control
                    type="password"
                    value={passwordInput}
                    onChange={e => setPasswordInput(e.target.value)}
                    onFocus={e => setIsPasswordInputTouched(true)}
                    placeholder="Password"
                    min={6}
                    isInvalid={
                        isPasswordInputTouched && passwordInput.length < 6
                    }
                    disabled={isPasswordInputDisabled}
                    required
                />
                <Form.Control.Feedback type="invalid">
                    Password must be at least 6 characters long.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="formGroup">
                <Form.Control
                    type="password"
                    value={confirmPasswordInput}
                    onChange={e => setConfirmPasswordInput(e.target.value)}
                    onFocus={e => setIsConfirmPasswordInputTouched(true)}
                    placeholder="Confirm Password"
                    min={6}
                    isInvalid={
                        isConfirmPasswordInputTouched && confirmPasswordInput !== passwordInput
                    }
                    disabled={isConfirmPasswordInputDisabled}
                    required
                />
                <Form.Control.Feedback type="invalid">
                    Passwords do not match.
                </Form.Control.Feedback>
            </Form.Group>
            <div className="submitButtonContainer">
                <Button align="center" variant="primary" type="submit" disabled={isButtonDisabled}>{isFetching ?
                    <div>Sign Up <Spinner animation="border" size="sm"/></div> : 'Sign Up'}</Button>
            </div>
        </Form>
    );

    const forgotPasswordForm = (
        <Form noValidate onSubmit={handleForgotPasswordSubmit}>
            <div className="formMessage">Enter your email address or username and we’ll send you a link to reset your
                password
            </div>
            <Form.Group className="formGroup">
                <Form.Control
                    type="email"
                    value={emailInput}
                    onChange={e => setEmailInput(e.target.value)}
                    placeholder="Email"
                    min={1}
                    isInvalid={
                        emailInput.length > 0 && !validator.validate(emailInput)
                    }
                    disabled={isEmailInputDisabled}
                    required
                />
                <Form.Control.Feedback type="invalid">
                    Please provide a valid email.
                </Form.Control.Feedback>
            </Form.Group>
            <li className="submitButtonContainer">
                <ul className="buttonGroup">
                    <li>
                        <Button align="center" variant="secondary" disabled={isBackToLoginButtonDisabled} onClick={() => switchToLoginForm()}>Back</Button></li>
                    <li>
                        <Button align="center" variant="primary" type="submit" disabled={isButtonDisabled}>{isFetching ? <div>Reset Password <Spinner animation="border" size="sm"/></div> : 'Reset Password'}</Button>
                    </li>
                </ul>
            </li>
        </Form>
    );

    const createPostForm = (
        <Form noValidate onSubmit={handleCreatePostSubmit}>
            <Form.Group className="formGroup">
                <Form.Control
                    type="text"
                    value={newPostTitle}
                    onChange={e => setNewPostTitle(e.target.value)}
                    min={1}
                    max={200}
                    placeholder="Title"
                    disabled={isCreatePostTitleInputDisabled}
                    isInvalid={
                        newPostTitle.length > 200
                    }
                />
                <Form.Control.Feedback type="invalid">
                    Title must be less than 200 characters long.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="formGroup">
                <Form.Control
                    type="text"
                    value={newPostContent}
                    onChange={e => setNewPostContent(e.target.value)}
                    min={1}
                    max={2500}
                    placeholder="Body"
                    disabled={isCreatePostContentInputDisabled}
                    as="textarea"
                    rows={10}
                    isInvalid={
                        newPostContent.length > 2500
                    }
                />
                <Form.Control.Feedback type="invalid">
                    Content must be less than 2500 characters long.
                </Form.Control.Feedback>
            </Form.Group>
            <div className="submitButtonContainer">
                <Button align="center" variant="primary" type="submit" disabled={isButtonDisabled}>{isFetching ? <div>Post <Spinner animation="border" size="sm"/></div> : 'Post'}</Button>
            </div>
        </Form>
    )

    const forgotPasswordQuestion = logInModalMode === "login" &&
        <div className="logInModalMessage" onClick={() => switchToForgotPasswordForm()}>
            <div className="link">Forgot password?</div>
        </div>

    const modalQuestion = () => {
        if (logInModalMode === "login") {
            return <div className="logInModalMessage">Don't have an account? <span className="link" onClick={() => switchToSignupForm()}>Sign Up</span></div>
        } else if (logInModalMode === "signup") {
            return <div className="logInModalMessage">Have an account? <span className="link" onClick={() => switchToLoginForm()}>Log In</span></div>
        } else {
            return "";
        }
    }

    const leftNav = (
        <div className="leftNav">
            <Button variant="primary" size="sm" onClick={() => openCreatePostModal()}>Create Post</Button>
            <Dropdown className="mainNavDropdown">
                <Dropdown.Toggle className="customDropdown">
                    <PersonCircle className="icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.ItemText><PersonCircle className="nameIcon"/> {currentUser.name}</Dropdown.ItemText>
                    <Dropdown.Item><NavItem text="PROFILE" func={goToProfilePage}><Person/></NavItem></Dropdown.Item>
                    <Dropdown.Item><NavItem text="SETTING" func={goToSettingPage}><Gear/></NavItem></Dropdown.Item>
                    <Dropdown.Item><NavItem text="LOGOUT" func={logout}><BoxArrowRight/></NavItem></Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );

    const loginSignupModal = (
        <Modal show={showLogInModal} onHide={handleCloseLoginModal} className="customModal" backdrop={backdropOfModal} keyboard={false}>
            <Modal.Header>
                <Modal.Title>{getLoginModalTitle()}</Modal.Title>
                <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={() => handleCloseLoginModal()} disabled={isCloseButtonOfModalDisabled}></button>
            </Modal.Header>
            <Modal.Body>
                {showBanner &&
                    <div className="banner"><Alert variant={bannerType}>{bannerMessage}</Alert></div>}
                {logInModalMode === "login" && loginForm}
                {logInModalMode === "signup" && signupForm}
                {logInModalMode === "forgot" && forgotPasswordForm}
            </Modal.Body>
            <Modal.Footer>
                {forgotPasswordQuestion}
                {modalQuestion()}
            </Modal.Footer>
        </Modal>
    )

    const createPostModal = (
        <Modal show={showCreatePostModal} onHide={handleCloseCreatePostModal} className="customModal" backdrop={backdropOfModal} keyboard={false}>
            <Modal.Header>
                <Modal.Title>Create Post</Modal.Title>
                <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={() => handleCloseCreatePostModal()} disabled={isCloseButtonOfModalDisabled}></button>
            </Modal.Header>
            <Modal.Body>
                {showBanner && <div className="banner"><Alert variant={bannerType}>{bannerMessage}</Alert></div>}
                {createPostForm}
            </Modal.Body>
        </Modal>
    )

    return (
        <div className="socialnetwork">
            <div className="mainNav">
                <NavItem text="HOME" func={goToHomePage}><House/></NavItem>
                {isLoggedIn ? leftNav : <NavItem text="LOGIN" func={() => openLoginModal()}><BoxArrowInRight/></NavItem>}
            </div>
            <div className="mainLayout">
                <div className="container">
                    <Outlet context={{authenticateUser, currentUser, isLoggedIn, logout}} />
                </div>
            </div>
            {loginSignupModal}
            {createPostModal}
        </div>
    )
};

export default SocialNetwork;
